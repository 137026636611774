<template>
  <!--  同步练习管理-->
  <div class="synchronizeExercises">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="overflow: hidden;display: flex;justify-content: space-between">
        <div class="menu_select">
          <h4 style="padding: 10px">模块</h4>
          <a-menu
              style="width: 100%"
              mode="inline"
              v-model="menu"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="index"
                @click="leftChange(index,item)"
            >
              {{item.name}}
            </a-menu-item>
          </a-menu>
          <div style="display: flex;justify-content: right;padding: 5px">
            <a-pagination
                size="small"
                v-model="leftPagination.pageNo"
                :total="leftPagination.total"
                @change="leftPageChange"/>
          </div>
        </div>
        <div class="InfoTable">
          <div class="select_Info">
            <div v-if="InfoTable.length!==0">
              所属分类：<a-button  style="margin: 5px;font-family: 微软雅黑" v-for="(list,index) in InfoTable" size="small" :class="{ liBackground:provincesStyle == index}" id="provincesBtn"
                             @click="provincesChange(index,list)">{{list.name}}</a-button>
            </div>
            <div v-else>
              所属分类： 无
            </div>
          </div>
          <div class="table_Info">
            <div class="select_Info">
              <a-button type="primary" @click="()=>{this.addShow = true;this.selectTypeList=[];this.ExamTypeListPage.pageNo=1;this.getExamTypeList(this.ExamTypeListPage.pageNo)}">新增</a-button>
              <exam-psd-down/>
            </div>
<!--            <div style="background-color: rgba(240, 242, 245, 1);min-width: 100%;height: 10px"></div>-->
            <a-table :columns="columns" style="min-height: 500px;padding-left: 15px;padding-right: 15px;" :data-source="tableList" rowKey="id"   :pagination="false"
            >
              <span slot="getType" slot-scope="text,item">
                {{ item.getType === 'DEFINED' ? '自定义' : '随机'}}
              </span>
              <span slot="questionCode" slot-scope="text,item">
                {{  item.questionTypeName}}
              </span>
              <span slot="number" slot-scope="text,item">
<!--                <a v-if="item.getType === 'DEFINED'" @click="examBtn(item.id)" >{{ item.questionSum }}</a>-->
                    <span >{{item.questionSum }}</span>
                </span>
              <span slot="switch" slot-scope="text,item">
                <a-switch v-model="item.isOpen=== 1" checked-children="是" un-checked-children="否" @click="switchChange(item,item.isOpen === 1? 0 : 1)"/>
              </span>
              <span slot="operate" slot-scope="text,item,index" style="display: flex;justify-content: space-between;width: 70%">
                <a v-if="index !== 0" @click="moveUp(item.id)">上移</a>
                <a v-if="index !== tableList.length - 1" @click="moveDown(item.id)">下移</a>
              <a style="margin-right: 10px" @click="editBtn(item)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
            </a-table>
            <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
              <a-pagination v-model="pagination.current"
                            :total="pagination.total"
                            @change="paginationChange" />
            </div>
          </div>
        </div>
      </div>

      <a-drawer
          :get-container="false"
          title="新增"
          width="600"
          :visible="addShow"
          @close="()=>{this.addShow = false;this.ExamAble=[];this.$refs.addItem.resetFields();
          this.ExamAble = [];this.addForm={exeType:undefined}}"
      >
        <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
<!--          <a-form-model-item label="封面图" prop="cover">-->
<!--            <div style="display: flex">-->
<!--              <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">-->
<!--                <div class="uploadImg">-->
<!--                  <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="fileId" @change="uploadPic('addCover')" ref="addCover" />-->
<!--                </div>-->
<!--                <div class="upload_image">-->
<!--                  <img-->
<!--                      alt=""-->
<!--                      v-if="addForm.cover"-->
<!--                      style="width: 95px;height:95px;"-->
<!--                      :src="addForm.cover"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="margin-left: 10px">-->
<!--                <a @click="openSample('synchronizeExercises_cover')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="同步练习分类" :prop="InfoTable.length!==0?'exeType':''">
            <a-select
                placeholder="请选择"
                v-model="addForm.exeType"
                @change="exeTypeChange($event,'add')"
            >
              <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id"   >
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <div style="display: flex">
              <a-input  v-model="addForm.name"  placeholder="请输入"  />
              <a @click="openSample('synchronizeExercises_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
<!--          <a-form-model-item label="题目类型" prop="getType">-->
<!--            <a-radio-group v-model="addForm.getType" @change="typeChange">-->
<!--&lt;!&ndash;              <a-radio value="DEFINED" >&ndash;&gt;-->
<!--&lt;!&ndash;                自定义&ndash;&gt;-->
<!--&lt;!&ndash;              </a-radio>&ndash;&gt;-->
<!--              <a-radio value= "RDNDOM">-->
<!--                随机-->
<!--              </a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="题库分类" prop="questionType" >
<!--            <a-select-->
<!--                placeholder="请选择"-->
<!--                v-model="addForm.questionType"-->
<!--            >-->
<!--              <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id"  @click="typeKey(item)">-->
<!--                {{item.name}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
            <a-select
                @popupScroll="popupScroll"

                option-filter-prop="children"
                style="width: 100%"
                placeholder="请选择"
                v-model="addForm.questionType"
            >
              <a-select-option v-for="(select,index) in selectTypeList" :key="select.id" :value="select.id" >
                {{select.typeName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
<!--          <a-form-model-item label="标签" prop="able"  >-->
<!--            <a-select-->
<!--                placeholder="请选择"-->
<!--                v-model="addForm.able"-->
<!--            >-->
<!--              <a-select-option v-for="item in ExamAble" :key="item.able" :value="item.able"  @click="ableSelect(item)">-->
<!--                {{item.able}}-{{item.count}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item-->
<!--              label="随机数量"-->
<!--              prop="questionNum"-->
<!--             >-->
<!--            <a-input v-model="addForm.questionNum"  placeholder="请输入"/>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="是否开启" prop="isOpen">
            <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false;
          this.ExamAble=[];
          this.$refs.addItem.resetFields();}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>


      <a-drawer
          :get-container="false"
          title="修改"
          width="600"
          :visible="editShow"
          @close="()=>{this.editShow = false;this.ExamAble = [];}"
      >
        <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
<!--          <a-form-model-item label="封面图" prop="cover">-->
<!--            <div style="display: flex">-->
<!--              <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">-->
<!--                <div class="uploadImg">-->
<!--                  <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" id="fileIds" @change="uploadPic('editCover')" ref="editCover" />-->
<!--                </div>-->
<!--                <div class="upload_image">-->
<!--                  <img-->
<!--                      alt=""-->
<!--                      v-if="editForm.cover"-->
<!--                      style="width: 95px;height:95px;"-->
<!--                      :src="editForm.cover"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="margin-left: 10px">-->
<!--                <a @click="openSample('synchronizeExercises_cover')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <span style="font-size: 10px">注：尺寸 宽280*高158，大小不超过500kb</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="同步练习分类" :prop="InfoTable.length!==0?'exeType':''">
            <a-select
                placeholder="请选择"
                v-model="editForm.exeType"
                @change="exeTypeChange($event,'edit')"
            >
              <a-select-option v-for="item in InfoTable" :key="item.id" :value="item.id"  >
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <div style="display: flex">
              <a-input  v-model="editForm.name"  placeholder="请输入"  />
              <a @click="openSample('synchronizeExercises_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>

          </a-form-model-item>
<!--          <a-form-model-item label="题目类型" prop="getType">-->
<!--            <a-radio-group v-model="editForm.getType"  @change="typeEdit">-->
<!--&lt;!&ndash;              <a-radio value='DEFINED' >&ndash;&gt;-->
<!--&lt;!&ndash;                自定义&ndash;&gt;-->
<!--&lt;!&ndash;              </a-radio>&ndash;&gt;-->
<!--              <a-radio value='RDNDOM'>-->
<!--                随机-->
<!--              </a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="题库分类" prop="questionType" >
            <a-select
                @popupScroll="popupScroll"

                option-filter-prop="children"
                style="width: 100%"
                placeholder="请选择"
                v-model="editForm.questionType"
            >
              <a-select-option v-for="(select,index) in selectTypeList" :key="select.id" :value="select.id" >
                {{select.typeName}}
              </a-select-option>
            </a-select>
<!--            <a-select-->
<!--                placeholder="请选择"-->
<!--                v-model="editForm.questionType"-->
<!--            >-->
<!--              <a-select-option v-for="item in ExamList" :key="item.id" :value="item.id" @click="typeKey(item)">-->
<!--                {{item.name}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
          </a-form-model-item>
<!--          <a-form-model-item prop="able" label="标签"  >-->

<!--            <a-select-->
<!--                placeholder="请选择"-->
<!--                v-model="editForm.able"-->
<!--            >-->
<!--              <a-select-option v-for="item in ExamAble" :key="item.able" :value="item.able" @click="ableSelect(item)">-->
<!--                {{item.able}}-{{item.count}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="随机数量" prop="questionNum" >-->
<!--            <a-input  v-model="editForm.questionNum"  placeholder="请输入"  />-->
<!--&lt;!&ndash;            <a-alert v-if="editForm.hasOwnProperty('questionType')" :message="'随机数量不要超过该题目分类总数'+count" type="warning"  close-text="x" />&ndash;&gt;-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="是否开启" prop="is_open">
            <a-switch :checked="editForm.isOpen!==0" @click="openEdit(editForm.isOpen === 1? 0 : 1)"
                      checked-children="是" un-checked-children="否"  />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false;
          this.ExamAble = [];}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-modal v-model="examShow" title="题目" width="70%" :footer="null" >
        <div style="display: flex;justify-content: space-between">
          <div>
            名称：<a-input style="width: 150px;margin-right: 10px" v-model="searchName"  @keyup.enter="searchEnter"/>
            <a-button  @click="searchExam">查询</a-button>
          </div>
          <div style="width: 35%;display: flex;justify-content: space-between">
            <a-button type="primary" @click="()=>{this.addExamShow = true}">新增</a-button>
            <a-button type="primary">从题库选择</a-button>
            <a-button type="primary">按分类导入</a-button>
          </div>
        </div>
        <a-table style="margin: 10px;min-height: 400px" :columns="ExamTitle" :data-source="examTable" rowKey="id"   :pagination="false">
           <span slot="name" slot-scope="text,item,index">
           {{'【'}} {{item.type === 'radio' ? '单选':'多选'}}{{'】'}}{{item.name}}
          </span>
          <span slot="operate" slot-scope="text,item,index">
           <a v-if="index !== 0" @click="examUp(item.id)">上移</a>
                <a  v-if="index !== examTable.length - 1" @click="examDown(item.id)">下移</a>
              <a style="margin-right: 10px;margin-left: 10px" @click="editBtnExam(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delExam(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
          </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="examPage.current"
                        :total="examPage.total"
                        @change="examPageChange" />
        </div>
      </a-modal>

      <!--    题目新增-->
      <a-drawer
          title="新增"
          width="600"
          :visible="addExamShow"
          @close="()=>{this.addExamShow = false}"
      >
        <a-form-model :rules="rules" :model="addExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addExamItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="addExamForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <a-select
                placeholder="选择分类"
                v-model="addExamForm.questionCode"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
                @focus="()=>{this.getExamList()}"
                @blur="()=>{this.examName =[]}"
            >
              <a-select-option v-for="item in examName" :key="item.id" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <a-input  v-model.trim="addExamForm.name"  placeholder="输入名称" @change="addTitle" />
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="display: flex;align-items: center"  v-for="(item,index) in addExamForm.options" :key="index">
              <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
              <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
              <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
              <input  type="checkbox" v-model="item.answer" v-if="addExamForm.type==='checkbox'" :id="'check'+index" @change="resultKey(item,index)">
              <input  type="radio" name="radio"  v-if="addExamForm.type==='radio'" :id="'check'+index" @change="resultRadio(item,index)">
              正确答案
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <a-textarea v-model="addExamForm.parse" />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addExamShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="addExamOk">
            确认
          </a-button>
        </div>
      </a-drawer>

<!--      题目修改-->
      <a-drawer
          title="修改"
          width="600"
          :visible="editExamShow"
          @close="()=>{this.editExamShow = false}"
      >
        <a-form-model :rules="rules" :model="editExamForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editExamItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="editExamForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <a-select
                placeholder="选择分类"
                v-model="editExamForm.questionCode"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
            >
              <a-select-option v-for="item in examName" :key="item.id" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <a-input  v-model="editExamForm.name"  placeholder="输入名称" @change="addTitle" />
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="display: flex;align-items: center"  v-for="(item,index) in editExamForm.options" :key="index">
              <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
              <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
              <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
              <input  type="checkbox" v-model="item.answer" v-if="editExamForm.type==='checkbox'" :id="'check'+index" @change="resultKeyEdit(item,index)">
              <input  type="radio" name="radio" :value="item.answer" v-if="editExamForm.type==='radio'" :id="'radio'+index" @change="resultRadioEdit(item,index)">
              正确答案
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <a-textarea v-model="editExamForm.parse" />
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editExamShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="editExamOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <PublicSample :propList="propList"></PublicSample>
    </a-spin>
  </div>
</template>

<script>

import {
  delExercises,
  delTitleExam,
  getExam, getExamLeft, getExamLeftNew,
  getExamOpenType,
  getExamTemplate,
  getExeExamListNew,
  getExercises,
  getExercisesId,
  getTitleExam,
  getTitleId,
  getTitleList,
  postDownExam,
  postDownExercises,
  postExam,
  postExercises,
  postTitleExam,
  postTitleType,
  postUpExam,
  postUpExercises,
  putExerciseMove,
  putExercises,
  putExercisesType,
  putTitleExam,
  questionAble, questionType
} from "@/service/titleExam_api";
import {update} from "@/utils/update";
import ExamPsdDown from '@/components/ExamPsdDownLoad'
import PublicSample from "@/utils/PublicSample";

export default {
  name: "synchronizeExercises",
  components: {
    ExamPsdDown,
    PublicSample
  },
  data(){
    var VerifyDigital=(rule,value,callback)=>{
      if(!value){
        callback(new Error("请输入"));
      }else{
        if(!(value >=0 && value <= this.ablCount)){
          callback(new Error("随机数量必须大于等于0，并且小于等于选择标签的数量!"));
        }else{
          callback();
        }
      }
    };
    return{
      leftPagination:{
        total:0,
        pageNo: 1,
        pageSize:10,
      },
      propList:{
        visible:false,
        code:null,
      },
      value: undefined,
      addShow:false,
      editShow:false,
      spinning:false,
      examShow:false,
      editExamShow:false,
      addExamShow:false,
      addExamForm:{
        options:[
          {
            answer:0,
            title:''
          }
        ],
        questionCode:[],
        parse:''
      },
      typeForm:{},
      provincesStyle:0,
      editForm:{
        able:undefined,
        questionNum:null
      },
      editExamForm:{},
      count:null,
      addForm:{
        isOpen:0,
        cover:'',
        exeType:undefined,
        // getType:'RDNDOM',
        // able:undefined,
        // questionNum:null,
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5},
      wrapperCol: { span: 16 },
      searchName:'',
      rules: {
        exeType:[{ required: true, message: '请选择分类', trigger: 'change' }],
        questionType:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        able:[{ required: true, message: '请选择', trigger: 'blur' }],
        questionCode:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        //VerifyDigital  判断写入的数量要小于选择的标签的数量
        questionNum:[{
          required: true,
          validator:VerifyDigital,
          trigger: 'blur' },
        ],
        cover:[{ required: true, message: '请上传封面图', trigger: 'blur' }],
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入code值', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        getType:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        options:[{required:true,message:'请输入选项',trigger: 'blur'}],
        titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
        key: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      columns:[
        {
          title: '名称',
          dataIndex: 'name',
        },
        // {
        //   title: '题目类型',
        //   scopedSlots: { customRender: 'getType' },
        // },
        {
          title: '题库分类',
          scopedSlots: { customRender: 'questionCode' },
        },
        {
          title: '题目数量',
          scopedSlots: { customRender: 'number' },
        },
        {
          title: '是否开启',
          scopedSlots: { customRender: 'switch' },
        },
        {
          title: '操作',
          width:'23%',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      ExamTitle:[
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text,record,index) => `${index+1}`,
          align:'center',
          width:'8%'
        },
        {
          title: '题目名称',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
          width:'20%'
        },
      ],
      ExamAble:[],
      ablCount:null,
      codeName:'',
      InfoTable:[],
      typeInfo:[],
      menu:[0],
      titleList:[],
      tableList:[],
      ExamList:[],
      examName:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      examPage:{
        total:0,
        current: 1,
        pageSize:10,
      },
      typeId:0,
      examTable:[],
      selectTypeList:[],
      ExamTypeListPage:{
        pageNo:1,
      }
    }
  },

  created() {
    this.$store.dispatch('setManageHeaderTitle',"同步练习管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
  mounted() {
    this.getTitleExam(1)
    this.getExam()

  },
  methods:{
    async getTitleExam(pageNum){
      // this.spinning =true
      const exam = await getExamTemplate(pageNum)
      if (exam.code === 200){
        this.typeInfo = exam.data
        // this.typeId = this.InfoTable[0].id
        this.leftPagination.total = exam.count
        this.$store.state.examId= this.typeInfo[0].id
        this.getTypeInfo(this.typeInfo[0].id)
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
      // this.spinning =false
    },
    leftPageChange(e){
      this.getTitleExam(e)
    },
    //获取所属分类
    async getTypeInfo(typeId){
      const exam = await getExamOpenType('EXE',typeId)
      if (exam.code === 200){
        this.InfoTable = exam.data
        this.typeId =typeId
        this.exeTypeId=exam.data.length!==0?exam.data[0].id:''
        this.addForm.exeType=exam.data.length!==0?exam.data[0].id:undefined
        this.getExamList_New(typeId,exam.data.length!==0?exam.data[0].id:'',1)
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }

    },
    //获取同步练习列表
    async getExamList_New(type,exeType,page){
      const  exam = await getExeExamListNew(type,exeType,page,this.examPage.pageSize)
      if (exam.code===200){
        this.tableList=exam.data
        this.examPage.total=exam.count
      }
    },
    //获取题库分类
    async getExam(){
      const Info =await getTitleExam()
      this.ExamList = Info.data
    },
    async getExamList(TitleExam){
      const exam = await getTitleExam(TitleExam)
      if (exam.code === 0){
        this.examName = exam.data
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
    },
    //分页跳转
    paginationChange(pageNumber){

      this.getExamList_New(this.typeId,this.exeTypeId,pageNumber)
    },
    //新增是否开启
    openWitch(checked){
      if (checked === false){
        this.addForm.isOpen = 0
      }else {
        this.addForm.isOpen = 1
      }
    },
    exeTypeChange(e,type){
      switch (type){
        case 'add':
          this.$set(this.addForm, 'exeType', e)
        break;
        case 'edit':
          this.$set(this.editForm, 'exeType', e)
              break;
      }

    },
    //左侧身份选择
    leftChange(index,row){
      this.$store.state.examId=row.id
      this.getTypeInfo(row.id)
      // if (value.length === 0 ){
      //   this.InfoTable = null
      //   this.tableList = null
      //
      // }else {
      //   this.provincesStyle = 0
      //   this.InfoTable = value
      //   this.tableList = value[0].exes
      //   this.typeId = value[0].id
      //   this.menu =index
      // }
      this.menu =index
    },
    //所属分类选择
     provincesChange(index,e){
        this.provincesStyle = index;
        this.exeTypeId = e.id
       this.addForm.exeType=e.id
       this.getExamList_New(this.typeId,this.exeTypeId,1)
    },
    //查询题目
    async examBtn(id,pageNum,name){
      const exam =await getExam(id,pageNum,name)
      this.examTable = exam.data.qtype
      this.examPage.total = exam.data.count
      this.examShow = true
      this.examId = id
    },
    examPageChange(e){
      this.examBtn(this.examId,e)
    },
    typeChange(e){
      if ( e.target.value === 'DEFINED'){
        delete this.addForm.questionNum
        delete this.addForm.questionType
      }
    },
    typeEdit(e){
      if ( e.target.value === 'DEFINED'){
        delete this.editForm.questionNum
        delete this.editForm.questionType
      }
    },
    async typeKey(item) {
      // this.count = item.count
      // //选择题库分类后，重新加载分类列表，并清空分类值以及数量值
      // this.codeName = item.id

      // this.addForm.able = undefined
      // this.editForm.able = undefined
      // this.addForm.questionNum = null
      // this.editForm.questionNum = null
      // this.ExamAble = []
      //获取分类列表
      // await this.getTypeList()
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.addExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.addExamForm.options.push({
          answer:0,
          title:''
        })}
    },
    //题目是否重复
    async addTitle(e){
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    resultKey(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    editOptions(){
      let ifTrue = true
      this.editExamForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.editExamForm.options.push({
          answer:0,
          title:''
        })}
    },
    delOptions(item,index){
      this.addExamForm.options.splice(index, 1)
    },
    delEditOptions(item,index){
      this.editExamForm.options.splice(index, 1)
    },
    //修改是否正确答案

    resultRadio(row,index){
      row.answer = 1
      this.addExamForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },
    resultRadioEdit(row,index){
      row.answer = 1
      this.editExamForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },

    resultKeyEdit(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    //搜索题目
     searchExam(){
       this.examBtn(this.examId,'',this.searchName)
       this.pagination.current = 1
    },
    searchEnter(){
      this.searchExam()
    },
    async edit(){
      this.$refs.editExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.editExamForm.options.length;i++){
            arr.push({
              title:this.editExamForm.options[i].title,
              answer:this.editExamForm.options[i].answer,
              id:i
            })
          }
          this.editExamForm.options =arr
          const add =await putTitleExam(this.editExamId,this.editExamForm)
          if (add.code === 0){
            await this.examBtn(this.examId)
            this.$message.success('修改成功')
            this.$refs.editExamItem.resetFields();
            this.editExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改题目
     editExamOk(){
       for(let i = 0; i < this.editExamForm.options.length; i++) {
         if (this.editExamForm.options[i].answer === 1 ){
           this.edit()
           return true;
         }
       }
       this.$message.error('未选择正确答案')
       return  false;
    },
    async add(){
      this.$refs.addExamItem.validate(async valid =>{
        if(valid){
          let arr =[]
          for (let i=0;i<this.addExamForm.options.length;i++){
            arr.push({
              title:this.addExamForm.options[i].title,
              answer:this.addExamForm.options[i].answer,
              id:i
            })
          }
          this.addExamForm.options =arr
          const add =await postExam(this.examId,this.user_name,this.addExamForm)
          if (add.code === 0){
            await this.examBtn(this.examId)
            this.$message.success('添加成功')
            this.$refs.addExamItem.resetFields();
            this.addExamForm={
              options:[
                {
                  answer:0,
                  title:''
                }
              ],
              questionCode:[],
              parse:''
            }
            this.addExamShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增题目
     addExamOk(){
       for(let i = 0; i < this.addExamForm.options.length; i++) {
         if ( this.addExamForm.options[i].answer === 1 ){
           this.add()
           return true;
         }
       }
       this.$message.error('未选择正确答案')
       return false;
    },
    //题目修改btn
    async editBtnExam(id){
      this.editExamId = id
      const edit =await getTitleId(id)
      if (edit.code === 0){
        this.editExamForm = edit.data
      }
      this.editExamShow = true
      setTimeout(() => {
        if (this.editExamForm.type==='radio'){
          let arr = document.getElementsByName("radio")
          arr.forEach((row,index)=>{
            if (row.value==='1'){
              row.checked=true
            }
          })

        }
      },100);
    await this.getExamList()
    },
    //上传封面图
    async uploadPic(type){
      const _this = this;
      let file = this.$refs[type].files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = async function () {
          let w = this.width;
          let h = this.height;
          if ( file.size > 500*1024) {
            _this.$message.warn('请上传大小500k以内的图片');
            return false;
          } else {
            const image = await update(file);
            if (image.code === 0) {
              switch (type) {
                case'addCover':
                  _this.addForm.cover = image.data.url
                  document.getElementById('fileId').value = null
                  break;
                case'editCover':
                  _this.editForm.cover = image.data.url
                  document.getElementById('fileIds').value = null

                  break;
              }
              _this.$message.success('上传成功')
            } else {
              _this.$message.error("上传失败，请重试。", image.message)
            }
          }
        }
      }

    },

    //获取分类列表
    async getTypeList() {
      let data = {
        id:this.codeName,
      }
      const exam = await questionAble(data)
      if (exam.code === 0){
        let list  = exam.data
        for (let i=0;i<list.length;i++){
          this.ExamAble.push({
            count: list[i].count,
            able: list[i].able,
          })
        }
      }else {
        this.$message.error(exam.message)
      }
    },
    ableSelect(item){
      this.ablCount = item.count
    },

    //新增确认
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          if (this.addForm.exeType===undefined){
            delete this.addForm.exeType
          }
          this.addForm.createdByName= this.user_name
          this.addForm.type=this.typeId
          console.log(this.addForm)
          const add =await postExercises(this.addForm)
          if (add.code === 200){
            // await this.getTitleExam()
            // this.InfoTable = this.typeInfo[this.menu].exes
            // this.tableList = this.InfoTable[this.provincesStyle].exes
            // this.$store.state.examId=this.typeInfo[this.menu].id


            this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
            this.provincesStyle = 0
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addShow = false
            this.ExamAble=[];
            // this.addForm.able = undefined;
            // this.addForm.questionNum = null;
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //练习题上移
    async moveUp(id){
     const move =  await  putExerciseMove(id,'up')
      if (move.code ===200){
        this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
        this.$message.success('操作成功')
      }
    },
    //练习题下移
    async moveDown(id){
       const move =await putExerciseMove(id,'down')
      if (move.code ===200){
        this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
        this.$message.success('操作成功')
      }
    },
    //题目上移
     examUp(id){
      postUpExam(id,this.examId)
      this.timer = setTimeout(()=>{
        this.examBtn(this.examId)
        this.$message.success('操作成功')
      },1000);
    },
    //题目下移
    examDown(id){
      postDownExam(id,this.examId)
      this.timer = setTimeout(()=>{
        this.examBtn(this.examId)
        this.$message.success('操作成功')
      },1000);
    },
    //修改Btn
    async editBtn(item){
      this.editId = item.id
      const edit =await getExercisesId(item.id)
      if (edit.code === 0){
        this.editForm = edit.data
        this.codeName = edit.data.questionType
      }
      this.editShow = true
      this.ExamAble = []
      this.selectTypeList=[]
      this.ExamTypeListPage.pageNo=1
      this.getExamTypeList(this.ExamTypeListPage.pageNo)
      //获取分类列表
      await this.getTypeList()
    },
    //是否开启
    async switchChange(row,open){
      const data = {
        id:row.id,
        isOpen : open,
        type:row.type,
        name:row.name,
        cover:row.cover,
        questionType:row.questionType,
      }
      const Info = await  putExercises(data)
      if (Info.code === 200){

        this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
        // await this.getTitleExam()
        // this.InfoTable = this.typeInfo[this.menu].exes
        // this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败' + Info.message)
      }
    },
    //修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit =await putExercises(this.editForm)
          if (edit.code === 200){
            // await this.getTitleExam()
            // this.InfoTable = this.typeInfo[this.menu].exes
            // this.tableList = this.InfoTable[this.provincesStyle].exes
            // this.$store.state.examId=this.typeInfo[this.menu].id
            this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
            this.$message.success('修改成功')
            this.$refs.editItem.resetFields();
            this.editShow = false
            this.ExamAble = [];
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改是否开启
    openEdit(open){
      this.editForm.isOpen = open
    },
    //删除
    async delExam(id){
      const Info =await delTitleExam(id)
      if (Info.code === 0){
        await this.examBtn(this.examId)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    async delTitle(id){
      const Info =await delExercises(id)
      if (Info.code === 200){

        this.getExamList_New(this.typeId,this.exeTypeId,this.pagination.current)
        // this.InfoTable = this.typeInfo[this.menu].exes
        // this.tableList = this.InfoTable[this.provincesStyle].exes
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    //取消删除
    cancel(){
      this.$message.warning('取消删除');
    },
    openSample(code){
      // code加上时间防止重复
      this.propList = {
        visible:true,
        code:code+'202303220916',
      }
    },


    //获取分类列表
    async getExamTypeList(pageNo) {
      let data = {
        page:pageNo,
        examTypeId:this.typeId,
        board: 'EXE'
      }
      const exam = await getExamLeftNew(data)
      if (exam.code === 200 && exam.data.length){
        let list  = exam.data
        for (let i=0;i<list.length;i++){
          this.selectTypeList.push({
            count: list[i].count,
            typeName: list[i].name,
            id: list[i].id,
          })
        }
      }
    },
    //滚动条监听
    popupScroll(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.ExamTypeListPage.pageNo++
        this.getExamTypeList(this.ExamTypeListPage.pageNo)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}
.titleList{
  padding: 0;
  margin: 0;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.synchronizeExercises{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      height: 60px;
      padding: 15px;
      align-items: center;

      background-color: white;
    }
    .table_Info{
      margin-top: 10px;
      background-color: white;
      .select_Info{
        display: flex;
        justify-content: space-between;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
</style>
